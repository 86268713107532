<script>

export default {
  name: 'StatCard',
  props: ['icon', 'title', 'value', 'center'],
  setup(props) {

  }
}
</script>

<template>
  <div class="d-flex stat-card" :class="{'flex-grow-1': $props.center !== true}">
    <div class="stat-card-icon">
      <v-icon>{{ $props.icon }}</v-icon>
    </div>
    <div class="ml-2">
      <div class="stat-card-title">{{ $props.title }}</div>
      <div class="stat-card-value" v-html="$props.value"></div>
    </div>
  </div>
</template>

<style scoped>
.loading {
  animation: all 1s ease;
  -webkit-animation: rotating 2s linear infinite;
}

.loading-text {
  font-weight:600;
  margin-top:10px;
}
</style>
