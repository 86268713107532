<script>
import { useSnackbarStore } from '@/stores/SnackbarStore.js'

export default {
  name: 'Snackbar',
  computed: {
    snackbar() {
      return useSnackbarStore();
    }
  }
}
</script>

<template>
    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      :multi-line="snackbar.multiline"
      variant="tonal"
      location="bottom right"
      elevation="10"

    >
      {{ snackbar.text }}
    </v-snackbar>
</template>
