<script>

export default {
  data() {
    return {
      min: 0,
      max: 0,
      datas: [],
      legend: [],
      dataWidth: 0,
      dataSpace: 0,
    }
  },
  name: 'ChartBar',
  props: ['values', 'maxValue'],
  created() {
    window.addEventListener("resize", this.setChartSetting);
  },
  destroyed() {
    window.removeEventListener("resize", this.setChartSetting);
  },
  methods: {
    setChartSetting() {
      if(this.$refs['chartBox'] !== undefined && this.$refs['chartBox'] !== null) {
        this.dataWidth = Math.floor(this.$refs['chartBox'].clientWidth / this.datas.length) * 0.75;
        this.dataSpace = Math.floor(this.$refs['chartBox'].clientWidth / this.datas.length * 0.25);
      }
    }
  },
  mounted() {
    const keys = Object.keys(this.$props['values']);
    const values = Object.values(this.$props['values']);

    values.forEach((value, index) => {
      if(this.$props['maxValue'] === undefined) {
          if(this.max < value['count']) {
            this.max = value['count'];
          }
      } else {
        this.max = this.$props['maxValue'];
      }

      this.datas.push(value['count']);
      this.legend.push(value['groupedDate']);
    });

    this.setChartSetting();
  }
}
</script>

<template>
  <div class="chart-bar mb-3 d-flex justify-center align-content-center" ref="chartBox">
    <div :style="'padding:0 ' + dataSpace/2 + 'px'" v-for="(value, index) in datas" class="char-section h-100 position-relative align-content-start d-flex flex-column-reverse">
      <div style="position:absolute;bottom:-17px;font-size:0.8em;color:#999;">{{ value }}</div>
      <div class="legend">{{ legend[index] }}</div>
      <div class="bar" :style="'height:' + (value / max *  100) + '%;width:' + (dataWidth) + 'px'"></div>
    </div>

  </div>
</template>

<style scoped>
.chart-bar {
  border:solid 1px #fff;border-radius:5px;height:200px;display:flex;align-items: end;

  .bar{
    height:60px;background:#548bff;border-top-left-radius:5px;border-top-right-radius:5px;
  }

  .char-section {
    cursor:pointer;
    display:flex;
    align-items: center;

    .legend {
      position:absolute;font-size:0.8em;color:#ccc;
      top:10px;display:none;background:#fefefe;
      animation: all 1s ease;
      padding:5px;min-width:80px;text-align:center;
      border-radius: 5px;
      box-shadow: 0px 0px 14px 2px #0000001F!important;
      z-index:100;
    }
    &:hover {
      background: #f6f6f6;

      .legend {
        display:block;
      }

      .bar {
        box-shadow: 0px 0px 5px 2px rgba(20, 44, 138, 0.2) !important;
      }
    }
  }




}
</style>
