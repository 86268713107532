import { defineStore } from 'pinia'
import AuthService from '@/services/AuthService.js'

const token = JSON.parse(localStorage.getItem('token'))
const initialState = token
  ? { status: { loggedIn: true }, token }
  : { status: { loggedIn: false }, token: null }

export const useAuthStore = defineStore('auth', {
  state: () => ({
    auth: initialState,
    user: {}
  }),
  actions: {
    async login(userData) {
      try {
        const loginResponse = await AuthService.login(userData)
        await this.me()
        this.auth = {
          status: { loggedIn: true },
          token: loginResponse,
        }
        return loginResponse
      } catch (error) {
        this.auth = {
          status: { loggedIn: false },
          token: null,
          type: null
        }
        throw error
      }
    },
    async me() {
      this.user = await AuthService.me()
    },
    refreshToken(newToken) {
      this.auth.status.loggedIn = true
      this.auth.token = { ...this.auth.token, access_token: newToken }
    },
    logout() {
      AuthService.logout().then(() => {
        this.auth = {
          status: { loggedIn: false },
          token: null
        }
        this.user = {}
      })
    }
  }
})
