<template>
  <v-app>
    <v-navigation-drawer v-model="drawer">
      <div>
        <div class="logo-nav mb-8">
          <img src="../assets/logo.png" style="width:45px;">
        </div>

          <router-link :to="'/dashboard'">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'Dashboard' || $route.name === 'Namespace'}">
            <div class="text-center">
              <v-icon>mdi-view-dashboard-outline</v-icon>
            </div>
            <div class="text-center mt-1 mb-4 nav-item-text">
             Dashboard
            </div>
          </div>
          </router-link>

        <div class="nav-sep" v-if="user.type ==='admin'"></div>

        <router-link :to="'/stats'" v-if="user.type ==='admin'">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'ProjectsStatSpec' || $route.name === 'ProjectsStat'}">
            <div class="text-center">
              <v-icon>mdi-chart-areaspline</v-icon>
            </div>
            <div class="text-center mt-1 mb-4 nav-item-text">
              Stats
            </div>
          </div>
        </router-link>

        <router-link :to="'/clusters'" v-if="user.type ==='admin'">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'Clusters'}">
            <div class="text-center">
              <v-icon>mdi-kubernetes</v-icon>
            </div>
            <div class="text-center mt-1 mb-4 nav-item-text">
              Clusters
            </div>
          </div>
        </router-link>

        <router-link v-if="user.type ==='admin'" :to="'/users'">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'Users' || $route.name === 'UsersAdd' || $route.name === 'UsersEdit'}">
            <div class="text-center">
              <v-icon>mdi-account-outline</v-icon>
            </div>
            <div class="text-center mt-1 mb-4 nav-item-text">
              Utilisateurs
            </div>
          </div>
        </router-link>

        <router-link v-if="user.type ==='admin'" :to="'/projects'">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'Projects' || $route.name === 'ProjectsAdd' || $route.name === 'ProjectsEdit'}">
            <div class="text-center">
              <v-icon>mdi-source-repository</v-icon>
            </div>
            <div class="text-center mt-1 mb-4 nav-item-text">
              Projets
            </div>
          </div>
        </router-link>

        <router-link v-if="user.type ==='admin'" :to="'/repositories'">
          <div class="nav-item" :class="{'nav-item-active' : $route.name === 'RepositoriesManagePage'}">
            <div class="text-center">
              <v-icon>mdi-gitlab</v-icon>
            </div>
            <div class="text-center mt-1 mb-4 nav-item-text">
              Gitlab
            </div>
          </div>
        </router-link>

        <div class="nav-sep"></div>

        <div class="nav-item" @click="logout">
          <div class="text-error text-center">
            <v-icon>mdi-power</v-icon>
          </div>
          <div class="text-center text-error nav-item-text mt-1 mb-4">
            Déconnexion
          </div>
        </div>

      </div>
    </v-navigation-drawer>

    <v-app-bar>

      <v-app-bar-title></v-app-bar-title>
      <div  class="d-flex justify-center align-content-center pr-5" style="margin:0;height:64px;padding-left:20px;">
        <img src="../assets/avatar.png" style="width:35px;height:35px;margin-top:12px;border-radius:50%;" class="rounded-be-circle">
        <div class="pl-3 pt-3">
          <div>{{ userFullName }}</div>
          <div style="color:#8d6868;font-size:0.8em;">{{ user.type }}</div>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
//get the user from the store and the drawer state, and the mutation to change the drawer state and the computed property to get the user
import { useAuthStore } from '@/stores/AuthStore.js'
import router from '@/router/index.js'

export default {
  setup() {
    const authStore = useAuthStore();
    return { authStore }
  },
  data() {
    return {
      drawer: true,
    }
  },
  computed: {
    user() {
      return this.authStore.user;
    },
    userFullName() {
      return `${this.user?.firstname} ${this.user?.lastname}`;
    }
  },
  methods: {
    async logout() {
      this.authStore.logout();
      router.push({ name: 'Login' });
    }
  }
}
</script>
