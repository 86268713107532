<template>
  <v-container>
    <v-row>
      <v-col class="" cols="3">
        <v-col col="12">
          <v-menu v-if="selectedPod !== null">
            <template v-slot:activator="{ props }">
              <v-card v-bind="props">
                <div class="d-flex">
                  <div class="flex-grow-1">
                    <div class="d-flex">
                      <div class="pr-3 pt-2">
                        <div v-if="selectedPod['status']['phase'] == 'Running' || selectedPod['status']['phase'] == 'Succeeded'" style="width:10px;height:10px;background:#25a125;border-radius:50%;"></div>
                        <div class="bg-error" v-if="selectedPod['status']['phase'] != 'Running' && selectedPod['status']['phase'] != 'Succeeded'" style="width:10px;height:10px;background:#25a125;border-radius:50%;"></div>
                      </div>
                      <div>
                        <div style="font-size:0.9em;color:#575757;">{{ selectedPod['metadata']['name'] }}</div>
                        <div style="font-size:0.8em;color:#aaa;margin-top:-5px;"> {{formatDate(selectedPod['metadata']['creationTimestamp'])}}</div>
                      </div>
                    </div>
                  </div>
                  <div><v-icon style="font-size:1.5em;color:#575757;">mdi-chevron-down</v-icon></div>
                </div>
              </v-card>
            </template>
            <v-list>
              <v-list-item v-for="item in ressources" class="pl-0 pr-0 pt-0 pb-0"  @click="loadLogs(item['metadata']['name'], item)">
                <v-list-item-title class="item-list">
                  <div class="d-flex">
                    <div class="pr-3 pt-2">
                      <div v-if="item['status']['phase'] == 'Running' || item['status']['phase'] == 'Succeeded'" style="width:10px;height:10px;background:#25a125;border-radius:50%;"></div>
                      <div class="bg-error" v-if="item['status']['phase'] != 'Running' && item['status']['phase'] != 'Succeeded'" style="width:10px;height:10px;background:#25a125;border-radius:50%;"></div>
                    </div>
                    <div>
                      <div style="font-size:0.8em;color:#575757;">{{ item['metadata']['name'] }}</div>
                      <div style="font-size:0.7em;color:#aaa;margin-top:-8px;"> {{formatDate(item['metadata']['creationTimestamp'])}}</div>
                    </div>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>

        <v-col cols="12" v-if="selectedPod !== null">
          <v-card>
            <div class="">
              <div class="">
                <div class="d-flex">
                  <div class="pr-3 pt-2">
                    <div v-if="selectedPod['status']['phase'] == 'Running' || selectedPod['status']['phase'] == 'Succeeded'" style="width:10px;height:10px;background:#25a125;border-radius:50%;"></div>
                    <div class="bg-error" v-if="selectedPod['status']['phase'] != 'Running' && selectedPod['status']['phase'] != 'Succeeded'" style="width:10px;height:10px;background:#25a125;border-radius:50%;"></div>
                  </div>
                  <div>
                    <div style="font-size:0.9em;color:#575757;">{{ selectedPod['metadata']['name'] }}</div>
                    <div style="font-size:0.8em;color:#aaa;margin-top:0px;"> {{formatDate(selectedPod['metadata']['creationTimestamp'])}}</div>
                  </div>
                </div>
                <div class="mt-3" style="margin-left:20px;">
                  <div>
                    <div class="mt-3 pb-3" style="font-size:0.9em;border-bottom:solid 1px #eee;">
                      <div class="text-primary">Image</div>
                      <div style="font-size:0.9em;">{{ selectedPod['spec']['containers'][0]['image'] }}</div>
                    </div>
                    <div v-if="selectedPod['spec']['containers'][0]['args'] !== undefined" class="pb-3 mt-3" style="font-size:0.9em;border-bottom:solid 1px #eee;">
                      <div class="text-primary">Args</div>
                      <div style="font-size:0.9em;">{{ (selectedPod['spec']['containers'][0] !== undefined && selectedPod['spec']['containers'][0]['args'] !== undefined && selectedPod['spec']['containers'][0]['args'][0] !== undefined) ? selectedPod['spec']['containers'][0]['args'][0] : '-'  }}</div>
                    </div>
                    <div class="mt-3 pb-3" style="font-size:0.9em;" v-if="selectedPod['spec']['containers'][0]['env'] !== undefined &&  selectedPod['spec']['containers'][0]['env'].length > 0">
                      <div class="text-primary">Environnement</div>
                      <div class="mt-2" style="font-size:0.9em;">
                        <div class="mb-3" v-for="env in selectedPod['spec']['containers'][0]['env']" >
                          <b style="color:#858585;">{{env['name']}}</b>
                          <div>{{env['value'] ?? '-'}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>

      </v-col>
      <v-col cols="9">

        <v-card class="mt-3 mb-6 d-flex pt-4 pb-4" v-if="selectedPod !== null">
          <div class="d-flex flex-grow-1" v-if="module === 'log'">
            <div class="pt-1 item-link text-primary"  @click="deletePods(selectedPod['metadata']['name'])">
              <v-icon style="font-size:1.5em;">mdi-restart</v-icon> <span v-if="!loading">Redémarrer</span> <span v-if="loading">Patientez ...</span>
            </div>

            <div v-if="this.selectedPod['metadata']['name'].includes('mysql')" class="pt-1 item-link ml-5 text-primary"  @click="mysql(selectedPod['metadata']['name'])">
              <v-icon style="font-size:1.5em;">mdi-database</v-icon> Accéder à la base de données
            </div>
          </div>
          <div class="d-flex flex-grow-1" v-if="module === 'mysql'">
            <div class="pt-1 item-link text-primary"  @click="module = 'log'" v-if="selectedTable === null">
              <v-icon style="font-size:1.5em;">mdi-arrow-left</v-icon> <span v-if="!loading">Retour aux logs</span> <span v-if="loading">Patientez ...</span>
            </div>
            <div class="pt-1 item-link text-primary"  @click="mysqlReturnTable()" v-if="selectedTable !== null">
              <v-icon style="font-size:1.5em;">mdi-arrow-left</v-icon> <span v-if="!loading">Retour aux tables</span> <span v-if="loading">Patientez ...</span>
            </div>
            <div class="pt-1 pl-4" v-if="selectedTable !== null">
              <v-icon style="font-size:1.5em;">mdi-table</v-icon>  {{ selectedTable }}
            </div>

            <div class="pl-4 item-link" style="padding-top:7px;color:#aaa;font-size:0.8em;" @click="editSql = true" v-if="module === 'mysql' && selectedPod !== null && selectedTable !== null">
              <v-icon style="font-size:1em;">mdi-pencil</v-icon> éditer la requête
            </div>

          </div>
          <div class="pt-1">
            <v-icon style="font-size:1.5em;">mdi-clock-outline</v-icon> {{dateSync}}
          </div>
        </v-card>
        <v-card v-if="selectedPod === null" class="pt-4 pr-4 pl-4 pb-4 mt-3 text-center">
          Veuillez sélectionner un pods
        </v-card>
        <v-card v-if="module === 'log' && selectedPod !== null" class="mt-3">
          <div v-if="logs.length === 0" class="pl-4 pr-4 pt-4 pb-4 text-center w-100">
            Aucun log pour <b class="text-primary">{{ selectedPod['metadata']['name'] }}</b>
          </div>
          <div v-for="(log, logindex) in logs" class="d-flex">
            <div style="color:#999;min-width:140px;font-size:0.9em;"  v-if="logs[logindex + 1] !== undefined && (log['container_id'] === logs[logindex + 1]['container_id'])">{{ moment(log['date']['date']).tz("Europe/Paris").format('DD/MM/YYYY HH:mm:ss') }}</div>
            <div style="color:#25a125;font-size:0.9em;"  v-if="logs[logindex + 1] !== undefined && (log['container_id'] === logs[logindex + 1]['container_id'])">{{ log['content'] }}</div>
            <div style="color:#919090;background:#f1f1f1;font-size:1em;padding:10px;text-align:center;border-top:solid 1px #eee;border-bottom:solid 1px #eee;width:100%;margin:5px 0;" v-if="logs[logindex + 1] !== undefined && (log['container_id'] !== logs[logindex + 1]['container_id'])">CHANGEMENT DE POD : {{ logs[logindex + 1]['container_id'] }}</div>
          </div>
        </v-card>
        <v-card v-if="module === 'mysql' && selectedPod !== null && selectedTable !== null && editSql" class="d-flex w-100" style="max-height:75px;">
          <div class="flex-grow-1"><v-text-field v-model="query" /></div>
          <div class="ml-3"><v-btn class="btn btn-primary" @click="queryMysql(this.selectedPod['metadata']['name'], this.selectedTable, this.query)">Executer</v-btn></div>
        </v-card>
        <v-card v-if="module === 'mysql' && selectedPod !== null" class="mt-3">
          <div class="text-center pt-4 pb-4" v-if="mysqlTable.length === 0 || (selectedTable !== null && mysqlValues.length === 0)">
            Chargement des données ...
          </div>

          <div v-if="mysqlCols.length > 0">
            <v-table class="w-100 v-table">
              <thead>
              <tr>
                <th v-for="col in mysqlCols">{{ col['name'] }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="row in mysqlValues">
                <td v-for="value in row">{{ value }}</td>
              </tr>
              </tbody>
            </v-table>
          </div>

          <div v-if="mysqlCols.length === 0 && selectedTable === null">
            <div v-for="table in mysqlTable">
              <div class="item-link text-primary cursor-pointer pt-2 pb-2" style="border-bottom:solid 1px #eee;" @click="queryMysql(selectedPod['metadata']['name'], table[0])">
                <v-icon style="font-size:1.5em;">mdi-table</v-icon> {{ table[0] }}
              </div>
            </div>
          </div>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from "@/services/DashboardService.js";
import timeago from 'vue-timeago3'
import {fr} from "date-fns/locale";
import VueMoment from 'vue-moment'
import moment from 'moment-timezone';
import "moment/locale/fr";
moment.locale('fr');

export default {
  name: 'NamespacePage',
  computed: {
    fr() {
      return fr
    }
  },
  components: { GuestCard, BackButton },
  data() {
    return {
      loading: false,
      ressources: [],
      namespaces: [],
      ramMax: 0,
      date: new Date(),
      logs: [],
      moment : moment,
      sync: null,
      syncPods: null,
      selectedPod: null,
      mysqlTable: [],
      mysqlCols: [],
      mysqlValues: [],
      mysqlPort: null,
      selectedTable: null,
      module: 'log',
      query: null,
      editSql: false,
      dateSync: new Date()
    }
  },
  beforeUnmount() {
    clearInterval(this.syncPods);
    clearInterval(this.sync);
  },
  methods: {
    async loadDashboard(first) {
      clearInterval(this.syncPods);
      try {
        await DashboardService.loadNamespace(this.$route.params.cluster, this.$route.params.id).then(response => {
          this.ressources = response['pods'];

          if(first) {
            this.selectedPod = this.ressources[0];
            this.loadLogs(this.ressources[0]['metadata']['name'], this.ressources[0])
          }

          this.syncPods = setTimeout(() => {this.loadDashboard(false)}, 3000);
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
      }
    },
    async deletePods(pod) {
      this.loading = true;
      await DashboardService.deletePods(this.$route.params.cluster, this.$route.params.id, pod).then(response => {
        this.selectedPod = null;
        this.loading = false;
        this.module = 'log';
        clearInterval(this.sync);
        this.logs = [];
      });
    },
    async loadLogs(pod, item) {
      this.selectedPod = item;
      clearInterval(this.sync);
      await DashboardService.loadLog(this.$route.params.cluster, this.$route.params.id, pod).then(response => {
        this.logs = response['logs']
        this.dateSync = moment(response['synchro']).tz("Europe/Paris").format('HH:mm:ss')
        this.sync = setTimeout(() => {this.loadLogs(pod, item)}, 1000);
      });
    },
    async mysql(pod) {
      this.module = 'mysql';
      this.mysqlTable = [];

      DashboardService.redirectPod(this.$route.params.id, pod, this.mysqlPort).then(response => {
        this.mysqlTable = response['tables']
        this.mysqlPort = response['port']
      })
    },
    async queryMysql(pod, table, query = null) {
      this.selectedTable = table;
      this.query = (query === null) ? 'select * from ' + table + ' LIMIT 0,100' : query;

      DashboardService.mysql(this.$route.params.id, pod, this.mysqlPort, this.query).then(response => {
        this.mysqlCols = response['cols']
        this.mysqlValues = response['tables']

        if(query !== null) {
          this.editSql = false;
        }
      })
    },
    mysqlReturnTable() {
      this.selectedTable= null;
      this.mysqlCols = [];
      this.mysqlValues = [];
      DashboardService.redirectPod(this.$route.params.id, this.selectedPod['metadata']['name'], this.mysqlPort).then(response => {
        this.mysqlTable = response['tables']
        this.mysqlPort = response['port']
      })
    },
    formatDate(dateString) {
      return this.moment(dateString).fromNow();
    }
  },
  mounted() {
    this.loadDashboard(true);
  }

}
</script>


<style scoped>
 .bar-bg {
   background:#1a1b1e;
   position:relative;
   height:25px;
   border-radius:10px;
   overflow:hidden;
 }

 .bar-line {
   width:20%;
   height:25px;
   background:#2a4f2a;
 }

 .bar-line-danger {
   background:#916022!important;
 }
</style>
