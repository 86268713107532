<template>
  <v-container class="h-100">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
          <Loader />
      </v-col>
    </v-row>
    <v-row v-if="error">
      <v-col cols="12">
        <v-card class="pt-8 pb-8 pl-8 pr-8 text-center">
          <div class="text-error"><v-icon style="font-size:5em;margin-top:8px;">mdi-alert</v-icon> </div>
          <div class="text-error">{{ this.errorMessage }}</div>
          <div class="fake-link mt-3" @click="this.error=false;this.loading=true;this.loadDashboard()">Ressayer</div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <div class="pl-4 pt-6 flex-grow-1" style="font-size:1.3em;color:#548bff;font-weight:300;">
        <v-icon>mdi-chevron-right</v-icon> Clusters
      </div>
      <div class="mt-2 mr-3">
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-card v-bind="props">
              <div class="d-flex" style="width:200px;">
                <div class="flex-grow-1">
                  <div class="d-flex">
                    <div class="pr-3 pt-2">
                    </div>
                    <div>
                      <div style="font-size:0.9em;color:#575757;">
                        {{ activeCluster !== null ? activeCluster['name'] : 'chargement' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <v-icon style="font-size:1.5em;color:#575757;">mdi-chevron-down</v-icon>
                </div>
              </div>
            </v-card>
          </template>
          <v-list>
            <v-list-item v-for="item in clusters" class="pl-0 pr-0 pt-0 pb-0">
              <v-list-item-title class="item-list" @click="selectCluster(item)">
                <div class="d-flex">
                  <div>
                    <div style="font-size:0.8em;color:#575757;">{{ item['name'] }}</div>
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-row>
    <v-row v-if="!loading">
      <v-col :cols="12 / ressources.length" v-for="item in ressources">
        <v-card>
          <v-row>
            <v-col cols="12" class="d-flex mb-0 pb-0">
              <div style="background:#f1f4ff;margin-top:3px;border-radius:10px;padding:5px;width:40px;height:40px;display:flex;justify-content:center;align-items:center;"><v-icon style="font-size:1.6em;color:#548bff">mdi-server-outline</v-icon></div>
              <div class="pl-3">
                <div style="font-size:1.1em;font-weight:600;color:#1c1C1C;">{{ item['name'] }}</div>
                <div style="margin-left:-5px;font-size:0.95em;"><v-icon style="color:#bbb" >mdi-memory</v-icon>  <span style="font-size:0.9em;color:#bbb;">{{(item['ram']['used'].toFixed(0))}} / {{item['ram']['total'].toFixed(0)}} Mo</span></div>
              </div>
            </v-col>
            <v-col cols="12" class="mt-0 pt-0" >
              <div class="d-flex mb-1">
                <div class="flex-grow-1">  </div>
                <div style="font-size:0.85em;color:#999;">{{ ((item['ram']['used'] / item['ram']['total']) * 100).toFixed(2) }} %</div>
              </div>
              <div class="bar-bg">
                <div v-if="(item['ram']['used'] / item['ram']['total'] * 100) < 80" class="bar-line" :style="'width:' + ((item['ram']['used'] / item['ram']['total']) * 100) + '%'"></div>
                <div v-if="(item['ram']['used'] / item['ram']['total'] * 100) >= 80" class="bar-line bar-line-danger" :style="'width:' + ((item['ram']['used'] / item['ram']['total']) * 100) + '%'"></div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <div class="pl-4 pt-3 page-title">
        <v-icon>mdi-chevron-right</v-icon> Projets
      </div>
    </v-row>
    <v-row>
      <v-col class="position-relative" cols="4" v-for="item, key in namespaces" :style="'z-index:' + (999 - key)">

        <v-card class="h-100 card-action overflow-visible">
        <div class="d-flex">
          <div class="flex-grow-1">
            <div class="d-flex mb-2 pl-3 pr-3 pb-3" style="z-index:999;position:relative;color:#548bff;">
              <div class="flex-grow-1">
                <div class="text-uppercase" style="font-weight:500">{{ item['metadata']['name'] }}</div>
                <div class="d-flex mt-4">
                  <div class="pod-success flex-grow-1">
                    <div>
                      <div class="text-center pl-5 pt-8 pb-5 pr-5" v-if="item['pods']['pods'].length === 0">Aucun pods pour ce namespace</div>
                      <template  v-for="pod, keyNamepspace in item['pods']['pods']">
                        <router-link :to="'/namespace/' + activeCluster['id'] + '/' + item['metadata']['name']">
                        <div class="d-flex mb-2 fake-link" @click="">
                          <div class="pr-3 pt-1">
                            <div class="bg-success" v-if="pod['status']['phase'] == 'Running' || pod['status']['phase'] == 'Succeeded'" style="width:10px;height:10px;border-radius:30%;"></div>
                            <div class="bg-error" v-if="pod['status']['phase'] != 'Running' && pod['status']['phase'] != 'Succeeded'" style="width:10px;height:10px;border-radius:30%;"></div>
                          </div>
                          <div class="flex-grow-1 pr-5">{{pod['metadata']['name']}}</div>
                          <div style="color:#a2a1a1;font-size:0.8em;">{{ pod['memory'] !== undefined ? pod['memory'].toFixed(2) : '0' }} Mo </div>
                        </div>
                        </router-link>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex pt-2 pb-2" style="font-size:0.85em;" v-if="item['isKube']">
                  <div class="ml-2" v-for="namespace in item['namespaces']">
                    <router-link :to="'/namespace/' + namespace"><div style="color:#548bff;background:#f1f4ff;margin-top:4px;border-radius:10px;padding:5px 10px;font-size:0.75em;"><v-icon style="font-size:1.7em;color:#548bff">mdi-kubernetes</v-icon> {{ namespace }}</div></router-link>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from "@/services/DashboardService.js";
import Loader from "@/components/ui/Loader.vue";
import StatCard from "@/components/ui/StatCard.vue";

export default {
  name: 'ClustersPage',
  components: {StatCard, Loader, GuestCard, BackButton },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: null,
      ressources: [],
      namespaces: [],
      clusters: [],
      projects: [],
      activeCluster: null,
      ramMax: 0,
      sync: null
    }
  },
  beforeUnmount() {
    clearInterval(this.sync);
  },
  methods: {
    async loadDashboard() {
      try {
        clearInterval(this.sync);
        let clusterUrl = this.activeCluster !== null ? this.activeCluster['id'] : 1;
        await DashboardService.loadCluster(clusterUrl).then(response => {
          this.ramMax = 0;
          this.error = false;
          this.sync = setTimeout(() => {this.loadDashboard()}, 3000);
          this.ressources = response['nodes'];
          this.namespaces = response['namespaces'];
          this.clusters = response['clusters'];

          if(this.activeCluster === null && response['clusters'][0] !== undefined) {
            this.activeCluster = response['clusters'][0];
          }
        })
      } catch (error) {
        handleResponseError(error, this.$t)
        this.error = true;
        this.errorMessage = (error['response']['data']['error'] !== undefined) ? error['response']['data']['error'] : 'Une erreur est survenue';
      } finally {
        this.loading = false
      }
    },
    selectCluster(item) {
      this.loading = true
      this.activeCluster = item;
      this.loadDashboard();
    }
  },
  mounted() {
    this.loading = true
    this.loadDashboard();
  }

}
</script>


<style scoped>
@keyframes center-animate {
  0% {
    background:#51a175
  }
  50% {
    background: #199350;
  }
  100% {
    background:#51a175;
  }
}

 .bar-bg {
   background:#ccc;
   position:relative;
   height:15px;
   border-radius:5px;
   overflow:hidden;

   &:hover {
     .bar-line {
       animation: center-animate 2s ease infinite alternate;
     }
   }
 }

 .bar-line {
   width:0;
   height:15px;
   background:#51a175;
   animation-delay: 2s;
   position:relative;
 }

 .bar-line-danger {
   background:orange!important;
 }
</style>
