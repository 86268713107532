import api from '@/services/ApiService'

class DashboarsService{
  async loadDashboard(cluster) {
    const response = await api.get('/dashboard?cluster=' + cluster)
    return response.data
  }
  async loadCluster(cluster) {
    const response = await api.get('/clusters?cluster=' + cluster)
    return response.data
  }
  async loadNamespace(cluster, name) {
    const response = await api.get('/namespace/' + name + '?cluster=' + cluster, {})
    return response.data
  }
  async loadLog(cluster, namespace, pod) {
    const response = await api.get('/logs/' + namespace + '/' + pod + '?cluster=' + cluster, {})
    return response.data
  }
  async deletePods(cluster, namespace, pod) {
    const response = await api.get('/delete/' + namespace + '/' + pod + '?cluster=' + cluster, {})
    return response.data
  }
  async redirectPod(namespace, pod, port) {
    let url = '/mysqlredirect/' + namespace + '/' + pod;

    if(port !== null) {
      url = url + '?port=' + port;
    }

    const response = await api.get(url, {})
    return response.data
  }
  async mysql(namespace, pod, port, query) {
    const response = await api.get('/mysqlredirect/' + namespace + '/' + pod + '?query=' + query + '&port=' + port, {})
    return response.data
  }
}

export default new DashboarsService()
