<template>
  <v-container class="h-100 d-flex">
    <v-row align="center" justify="center">
      <v-card
        variant="elevated"
        class="pt-8 pb-16 rounded-md"
      >
        <div :class="(isLogoCentered ? 'text-center' : 'text-left') + ' mb-6 px-8 d-flex'">
          <img alt="Logo Agora" class="logo" src="@/assets/logo.png" style="width:60px">
          <div class="text-primary pl-8 pt-3">
            <div style="font-size:2em;font-weight:600!important;">Leviatan Tools</div>
          </div>
        </div>
        <v-divider class="mb-6"></v-divider>
        <div v-if="$slots.header" class="px-8">
          <slot name="header" />
        </div>
        <div class="px-8">
          <slot />
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'GuestCard',
  props: {
    isLogoCentered: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.v-card {
  width: 100%;
  max-width: 410px;
}
</style>
