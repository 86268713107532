<template>
  <v-container class="h-100">
    <v-row class="h-100" v-if="loading">
      <v-col cols="12 h-100 d-flex" style="justify-content: center;align-items: center">
        <Loader />
      </v-col>
    </v-row>
    <v-row v-if="!loading" class="d-flex pr-4 pt-3 pb-1">
      <div class="pl-4 pt-3 flex-grow-1 page-title">
        <v-icon>mdi-chevron-right</v-icon> Gitlab > Variables > Add
      </div>
      <div>

      </div>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card>
          <div class="d-flex">
            <v-form class="w-100" v-model="isFormValid" @submit.prevent="sendForm">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <div class="form-group">
                    <div class="form-label">Valeur</div>
                    <div class="form-input">
                      <v-text-field
                          v-model="variableKey"
                          type="text"
                          required
                          autofocus
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-label">Type</div>
                    <div class="form-input">
                      <v-select  v-model="variableType" :items="['env_var', 'file']">

                      </v-select>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-label">Clé</div>
                    <div class="form-input">
                      <v-textarea
                          v-model="variableValue"
                          :type="'text'"
                          rows="15"
                          required
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row align="center" class="mt-2">
                <v-col cols="12" class="text-center">
                  <v-btn
                      text="Modifier"
                      :loading="loading"
                      :disabled="!isFormValid"
                      type="submit"
                  />
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService.js'
import { useSnackbarStore } from '@/stores/SnackbarStore.js'
import BackButton from '@/components/ui/BackButton.vue'
import router from '@/router/index.js'
import GuestCard from '@/components/guest/GuestCard.vue'
import { handleResponseError } from '@/components/utils/globalFunctions.js'
import DashboardService from "@/services/DashboardService.js";
import timeago from 'vue-timeago3'
import {fr} from "date-fns/locale";
import VueMoment from 'vue-moment'
import moment from 'moment-timezone';
import "moment/locale/fr";
import UsersService from "@/services/UsersService.js";
import ProjectsService from "@/services/ProjectsService.js";
import Loader from "@/components/ui/Loader.vue";
moment.locale('fr');

export default {
  name: 'RepositoriesManageVariableAddPage',
  computed: {
    fr() {
      return fr
    }
  },
  components: {Loader, GuestCard, BackButton },
  data() {
    return {
      loading: false,
      isFormValid: false,
      variableKey: null,
      variableValue: null,
      variableType: null,
      variable: [],
      date: new Date(),
      moment : moment,
    }
  },
  beforeUnmount() {
  },
  methods: {
    async loadProjects() {
      this.loading = true
      try {
        ProjectsService.gitlabVariable(this.$route.params.id).then(response => {
          response['variables'].forEach(variable => {
            if(variable['key'] === this.$route.params.name) {
             this.variable = variable;
             this.variableKey = variable['key'];
             this.variableValue = variable['value'];
             this.variableType = variable['variable_type'];
            }
          });
        })
      } catch (error) {
        handleResponseError(error, this.$t)
      } finally {
        this.loading = false
      }
    },
    async deleteUser(id) {
      let check = confirm('Etes vous sure ?')

      if(check) {
        ProjectsService.projectDelete(id).then(() => {
          useSnackbarStore().openSnackbar({
            text: 'Projet supprimé avec succès',
            color: 'success'
          })
          this.loadProjects();
        });
      }

    },
    editVariables(id) {
      ProjectsService.gitlabVariable(id).then(response => {
        console.log(response);
      })
      //this.$router.push({ name: 'ProjectsEdit', params: {id : id} });
    },
    async sendForm() {
      ProjectsService.gitlabVariableCreate(this.$route.params.id, this.variableKey, this.variableValue, this.variableType).then(response => {
        if(response['success']) {
          this.$router.push({ name: 'RepositoriesManageVariablePage', params: {id : this.$route.params.id} });
        } else {
          alert('erreur de mise a jour');
        }
      })
    }
  },
  mounted() {
    if(localStorage.getItem('type') !== 'admin') {
      this.$router.push({ name: 'Dashboard' });
    }

    this.loading = true;
    this.loadProjects();
  }

}
</script>
