import axiosInstance from './ApiService.js'
import TokenService from './TokenService.js'
import { useAuthStore } from '@/stores/AuthStore.js'

const setup = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken()
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      const originalConfig = error.config

      if (
        originalConfig.url !== '/auth/login' &&
        originalConfig.url !== '/auth/refresh-token' &&
        originalConfig.url !== '/auth/logout' &&
        error.response
      ) {
        // Access Token was expired
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true
          try {
            const response = await axiosInstance.post('/auth/refresh-token', {
              refresh_token: TokenService.getLocalRefreshToken()
            })

            const { access_token } = response.data

            useAuthStore().refreshToken(access_token)
            TokenService.updateLocalAccessToken(access_token)

            return axiosInstance(originalConfig)
          } catch (_error) {
            useAuthStore().logout()
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(error)
    }
  )
}

export default setup