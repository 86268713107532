<script>
import { RouterView } from 'vue-router';
import Snackbar from '@/components/ui/Snackbar.vue'
import { useAuthStore } from '@/stores/AuthStore.js'
import { mapStores } from 'pinia'


export default {
  components: {
    Snackbar,
    RouterView
  },
  computed: {
    ...mapStores(useAuthStore),
    layout() {
      return this.$route.meta.layout || 'div';
    },
    loggedIn() {
      return this.authStore.auth.status.loggedIn;
    },
  },
  async created() {
    if (this.loggedIn) {
     await this.authStore.me();
    }
  }
}
</script>

<template>
  <component :is="layout">
    <Snackbar />
    <RouterView />
  </component>
</template>
