import api from '@/services/ApiService'

class UsersService{
  async clustersList() {
    const response = await api.get('/clusters-list')
    return response.data
  }
  async namespaceList(cluster) {
    const response = await api.get('/namespace-list/' + cluster)
    return response.data
  }
  async usersList() {
    const response = await api.get('/users')
    return response.data
  }
  async usersDetail(id) {
    const response = await api.get('/users/' + id)
    return response.data
  }
  async createUser(user) {
    const response = await api.post('/users', user)
    return response.data
  }
  async editUser(id, user) {
    const response = await api.put('/users/' + id, user)
    return response.data
  }
  async deleteUser(user) {
    const response = await api.delete('/users/' + user)
    return response.data
  }
}

export default new UsersService()
