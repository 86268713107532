import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

import { createApp } from 'vue'

import App from './App.vue'
import router from './router'

import * as Sentry from "@sentry/vue";
import i18n from './i18n/config'
import Interceptor from '@/services/Interceptor.js'
import vuetify from '@/plugins/vuetify.js'
import './assets/base.scss'
import { createPinia } from 'pinia'
import timeago from 'vue-timeago3'
import moment from 'moment';
import VueMoment from 'vue-moment';


const pinia = createPinia()
const app = createApp(App)

if (import.meta.env.VITE_DSN_SENTRY &&
  import.meta.env.VITE_TRACES_SAMPLE_RATE &&
  import.meta.env.VITE_REPLAYS_SESSION_SAMPLE_RATE &&
  import.meta.env.VITE_REPLAYS_ON_ERROR_SAMPLE_RATE) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_DSN_SENTRY,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: import.meta.env.VITE_TRACES_SAMPLE_RATE,
    replaysSessionSampleRate: import.meta.env.VITE_REPLAYS_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: import.meta.env.VITE_REPLAYS_ON_ERROR_SAMPLE_RATE
  });
}

Interceptor()

app.use(pinia)
app.use(router)
app.use(vuetify)
app.use(i18n)
app.use(timeago)
app.mount('#app')
