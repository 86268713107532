import { defineStore } from 'pinia'

export const useSnackbarStore = defineStore('snackbar', {
  state: () => ({
    visible: false,
    text: '',
    color: 'success',
    multiline: false,
  }),
  actions: {
    openSnackbar(parameters){
      this.visible = true
      this.text = parameters.text
      this.color = parameters.color
      this.multiline = parameters.text > 50
    }
  }
})