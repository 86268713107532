<script>

export default {
  name: 'Loader',
}
</script>

<template>
  <div class="pt-10 pb-10" style="min-width:200px;">
    <div class="loading" style="width:65px;margin:0 auto;"><img src="../../assets/logo.png" style="width:65px;"></div>
    <div class="loading-text text-center">Chargement en cours</div>
  </div>
</template>

<style scoped>
.loading {
  animation: all 1s ease;
  -webkit-animation: rotating 2s linear infinite;
}

.loading-text {
  font-weight:600;
  margin-top:10px;
}
</style>
