<script>

export default {
  name: 'BackButton',
}
</script>

<template>
  <div>
    <a @click="$router.go(-1)">
      <v-icon
        class="animated"
      >
        mdi-arrow-left
      </v-icon>
      <span class="font-weight-semi-bold">{{ $t('FORGOT_PASSWORD_PAGE.BUTTON.BACK') }}</span>
    </a>
  </div>
</template>

<style scoped>
  a .animated {
    transition: all 0.3s;
    margin-right: .5rem;
  }
  a:hover .animated {
    margin-right: .15rem;
  }
</style>