class TokenService {
  getLocalRefreshToken() {
    const token = JSON.parse(localStorage.getItem('token'))
    return token?.refresh_token
  }

  getLocalAccessToken() {
    const token = JSON.parse(localStorage.getItem('token'))
    return token?.access_token
  }

  updateLocalAccessToken(updatedToken) {
    let token = JSON.parse(localStorage.getItem('token'))
    token.access_token = updatedToken
    localStorage.setItem('token', JSON.stringify(token))
  }
}

export default new TokenService()